<template>
  <div class="categories-container">
    <div class="category-item-container" v-for="item in categories" :key="item">
      <ClientOnly>
        <CategoryCard @click="() => click(item)" :animationName="item" />
      </ClientOnly>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const categories: Array<CategoryType> = [
      'smartfon',
      'tablet',
      'laptop',
      'konsola',
    ];

    return { categories };
  },
  methods: {
    click(selectedCategory: CategoryType) {
      navigateTo(`/${selectedCategory}`);
    },
  },
});
</script>

<style scoped>
.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.category-item-container {
  flex-basis: 170px;
}

@media screen and (max-width: 480px) {
  .category-item-container {
    flex-basis: 163px;
  }
}
</style>
