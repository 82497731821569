<template>
  <div
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @focusin="mouseEnter"
    @focusout="mouseLeave"
    class="container"
  >
    <Vue3Lottie
      class="animation"
      :autoPlay="false"
      :noMargin="true"
      :scale="1.055"
      :loop="false"
      ref="animationRef"
      :animationData="animation"
    />
    <h4 class="title">{{ title }}</h4>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';

import consoleJSON from '~/assets/animations/console.json';
import laptopJSON from '~/assets/animations/laptop.json';
import mobileJSON from '~/assets/animations/mobile.json';
import tabletJSON from '~/assets/animations/tablet.json';

export default defineComponent({
  name: 'CategoryCard',
  components: {
    Vue3Lottie,
  },
  props: {
    animationName: {
      required: true,
      type: String as PropType<CategoryType>,
    },
  },
  setup(props) {
    const animationRef = ref();
    let animation;
    let title;

    switch (props.animationName) {
      case 'konsola':
        animation = consoleJSON;
        title = 'Konsola';
        break;
      case 'laptop':
        animation = laptopJSON;
        title = 'Laptop';
        break;
      case 'smartfon':
        animation = mobileJSON;
        title = 'Smartfon';
        break;
      case 'tablet':
        animation = tabletJSON;
        title = 'Tablet';
        break;
      default:
        break;
    }

    return { animation, animationRef, title };
  },
  methods: {
    mouseEnter() {
      this.animationRef.setDirection('forward');
      this.animationRef.play();
    },
    mouseLeave() {
      this.animationRef.pause();
      this.animationRef.setDirection('reverse');
      this.animationRef.play();
    },
  },
});
</script>

<style scoped>
.container {
  width: 162px;
  max-height: 199px;
  background-color: #FFFFFF;
  box-shadow: 2px 6px 24px 0px #0D4D2E0F;
  border-radius: 12px;
  padding: 4px;
  padding-bottom: 16px;
  cursor: pointer;
}

.animation {
  background: linear-gradient(130.67deg, #DBF1FB -21.42%, #CEE1F8 105.56%);
  border-radius: 8px;
}

.title {
  padding-top: 16px;
}

@media screen and (max-width: 480px) {
  .container {
    width: 154px
  }
}
</style>
